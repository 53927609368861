<script setup lang="ts">
import { PhSpinner } from '@phosphor-icons/vue'
import { useQuery } from '@tanstack/vue-query'
import { useSessionStorage, watchImmediate } from '@vueuse/core'
import dayjs from 'dayjs'
import { ref } from 'vue'
import BaseTabsList from '~/components/Base/BaseTabsList.vue'
import { toast } from '~/components/ui/toast'
import usePaymentsComposable from '~/composables/usePaymentComposable'
import type { IBank, Invoice } from '~/shared/interfaces'
import { useAuthStore } from '~/stores/auth'
import type { FXQuote } from '~/types/apiResponse/fx.payment'
import type { ValidatePaymentResponse } from '~/types/apiResponse/payments.response'
import type { BillPaymentPricing } from '~/types/models/billPaymentLoans.model'
import type { MakeSingleBillPaymentWithOpenBankingPayload } from '~/types/apiPayload/bills.payload'
import {
  getBillStatusClasses,
  insertInvisibleElementWithIdForPendo,
  statusColors,
} from '~/lib/utils'
import { SESSION_STORAGE_KEYS } from '~/constants/CONSTANTS'

interface Props {
  bill: Invoice
}
interface Emits {
  (e: 'isBusy', value: boolean): void
  (e: 'success'): void
  (e: 'editBill'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const tabs = ['open-banking', 'credit-card', 'lenkie'] as const

const authStore = useAuthStore()
const profileStore = useProfileStore()
const { isGNPLUser, organisationId } = storeToRefs(profileStore)
const { personId } = storeToRefs(authStore)

const { $api, $event } = useNuxtApp()
const router = useRouter()

const { Openbanking, CardPay, CreditFacility } = usePaymentsComposable()
const { invalidateAllBillListQueries } = useQueryUtilitiesFns()

const hasInvoice = computed(() => !!props.bill.invoice_url)
const selectedBank = ref<string>()
const selectedBankDetails = ref<IBank>()
const gnplPricingDetails = ref<BillPaymentPricing>()
const fxQuote = ref<FXQuote>()
const priceId = ref<string>()
const cardSelected = ref<string>()
const isScheduled = ref(false)
const scheduleDate = ref<string>(dayjs().add(1, 'day').toISOString())

const isPaying = ref(false)
const showOTPModal = ref(false)

const {
  data: validatedOptionsData,
  isLoading: isValidating,
  isFetching: isFetchingValidation,
} = useQuery({
  queryKey: ['validate-single-payment', { ...props.bill }],
  queryFn: () =>
    $api.banking.payments.validatePaymentMethods({
      amount: props.bill.amount,
      beneficiary_id: props.bill.beneficiary.id,
      organisation_id: organisationId.value!,
      currency_code: props.bill.currency,
    }),
  enabled: computed(
    () =>
      !!organisationId.value &&
      (props.bill.bill_status === 'ReadyForPayment' ||
        props.bill.bill_status === 'Canceled'),
  ),
  select(data) {
    return data.data
  },
  refetchOnMount: 'always',
})

const validatedOptions = computed(() => {
  return validatedOptionsData.value || []
})

const paymentMethods = computed(() => {
  const availabilityAndMessage = validatedOptions.value.reduce(
    (accm, curr) => {
      let obj = {
        message: curr.message,
        valid: curr.is_valid,
        errorType: curr.error_type,
      }

      if (curr.payment_method === 'CardPay') {
        obj = {
          ...obj,
          valid: obj.valid,
          message: obj.message ? obj.message : '',
        }
      }

      if (curr.payment_method === 'PayNowWithGnpl') {
        obj = {
          ...obj,
          valid: obj.valid && hasInvoice.value,
          message: obj.message
            ? obj.message
            : !hasInvoice.value
              ? 'No invoice(s) attached to this bill'
              : '',
          errorType: obj.errorType
            ? obj.errorType
            : !hasInvoice.value
              ? 'Bill'
              : null,
        }
      }

      accm[curr.payment_method] = obj

      return accm
    },
    {
      CardPay: { message: '', valid: false, errorType: null },
      OpenBank: { message: '', valid: false, errorType: null },
      PayNowWithGnpl: { message: '', valid: false, errorType: null },
    } as Record<
      ValidatePaymentResponse[number]['payment_method'],
      {
        valid: boolean
        message: string | null
        errorType: ValidatePaymentResponse[number]['error_type']
      }
    >,
  )

  const options = {
    lenkie: {
      label: 'Pay with Lenkie',
      icon: 'wallet',
      isAvailable: availabilityAndMessage.PayNowWithGnpl,
      hide: !isGNPLUser.value,
    },
    'open-banking': {
      label: 'Bank transfer',
      icon: 'bank',
      isAvailable: availabilityAndMessage.OpenBank,
      hide: false,
    },
    'credit-card': {
      label: 'Pay with card',
      icon: 'credit-card',
      isAvailable: availabilityAndMessage.CardPay,
      hide: true,
    },
  } as const

  return options
})

const tabsShown = computed(() => {
  type Keys = keyof typeof paymentMethods.value
  return Object.entries(paymentMethods.value)
    .filter((entry) => {
      const [_key, value] = entry
      return !value.hide
    })
    .map((entry) => {
      const [key, _value] = entry
      return key as Keys
    })
})

const activeTab = ref<(typeof tabs)[number]>(tabsShown.value[0])

function goToPaidTabAfterPayment() {
  $event('fetch:bills', {
    trigger: true,
    goToTab: 'Paid',
    params: { Page: 1 },
  })

  router.push({
    path: '/bills',
    query: {
      status: 'paid',
    },
  })
}

function goToScheduledTabAfterPayment() {
  $event('fetch:bills', {
    trigger: true,
    goToTab: 'Scheduled',
    params: { Page: 1 },
  })

  router.push({
    path: '/bills',
    query: {
      status: 'scheduled',
    },
  })
}

async function finallyMakePaymentWithCardAfterChecks() {
  const { makeSingleBillPaymentWithCardPay } = CardPay

  if (!props.bill) {
    return
  }

  toast({
    title: 'Payment processing',
    description: 'We are processing your payments, please wait...',
  })

  try {
    isPaying.value = true
    const { status } = await makeSingleBillPaymentWithCardPay({
      payment_type: 'CardPay',
      payment_request_data: {
        payment_method_id: cardSelected.value!,
      },
      otp: null,
      session_code: null,
      user: null,
      bill_id: props.bill.id,
      // agreed_to_pay_unverified_vendor: showriskConsent.value
      //   ? riskConsentAgreed.value
      //   : null,
    })

    if (status === 200) {
      $event('track:mixpanel', {
        event: 'CardPay payment successful',
        data: { ...props.bill },
      })
      showOTPModal.value = false
      toast({
        title: 'Payment successful',
        variant: 'default',
        description: `Your payment was successful, please wait while we redirect you to the payments page`,
      })
      // closeSummary()
      invalidateAllBillListQueries()
      goToPaidTabAfterPayment()
    }
  } catch (error: any) {
    $event('track:mixpanel', {
      event: 'CardPay payment failed',
      data: props.bill,
    })
    toast({
      variant: 'destructive',
      title: 'Payment failed',
      description:
        error?.response?.data?.error_message ||
        error?.response?.data?.failure_reasons?.['error-message']?.[0] ||
        'Error occurred',
    })
  } finally {
    isPaying.value = false
  }
}

async function makeCardPayOrCreditFacilityPayment(pin: number) {
  const { validate2fa } = CardPay
  $event('track:mixpanel', {
    event: `Bills payment initiated (${activeTab.value === 'lenkie' ? 'GNPL' : 'Credit-Card'})`,
    data: { ...props.bill },
  })
  try {
    isPaying.value = true
    const twoFaIsValid = await validate2fa(pin.toString())
    if (!twoFaIsValid) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Wrong OTP code, please try again',
      })
      return
    }
    if (activeTab.value === 'credit-card') {
      await finallyMakePaymentWithCardAfterChecks()
    } else if (activeTab.value === 'lenkie') {
      await finallyMakePaymentWithCreditFacilityAfterChecks()
    }
  } catch (error: any) {
    toast({
      variant: 'destructive',
      title: 'Payment failed',
      description:
        error?.response?.data?.error_message ||
        error?.response?.data?.failure_reasons?.['error-message']?.[0] ||
        Object.values(error?.response?.data?.failure_reasons || {})?.[0] ||
        'Failed to make payment, please contact us at hello@lenkie.com',
    })
  } finally {
    isPaying.value = false
    showOTPModal.value = false
  }
}

function handleShowBulkPaymentSuggestion() {
  const attempts = useSessionStorage<string[]>(
    SESSION_STORAGE_KEYS.PAYMENT_ATTEMPTS_COUNT,
    [],
  )
  const now = dayjs()
  attempts.value = [...attempts.value, now.toISOString()]

  const recentAttempts = attempts.value.filter((attempt) => {
    return now.diff(dayjs(attempt), 'minute') <= 5
  })

  if (recentAttempts.length >= 2) {
    function triggerGuide() {
      // Get the list of Pendo guides from the global window object
      const pendoGuides = (window as any)?.pendo?.guides

      // Check if pendoGuides is an array and has at least one guide
      if (!Array.isArray(pendoGuides) || pendoGuides.length === 0) return

      // Find the specific guide for the bulk payment feature
      const bulkBillGuide = pendoGuides.find(
        (guide: { name: string }) => guide.name === 'Bulk payment feature',
      )

      // If the guide doesn't exist or has been dismissed 2 or more times, exit the function
      if (!bulkBillGuide || bulkBillGuide.guideDismissCount >= 2) return

      // Launch the guide if the conditions are met
      bulkBillGuide.launch()
    }

    triggerGuide()
    attempts.value = []
  } else {
    attempts.value = recentAttempts
  }
}

const finallyMakePaymentWithCreditFacilityAfterChecks = async () => {
  const { makeSingleBillPaymentWithCreditFacility } = CreditFacility

  if (!gnplPricingDetails.value || !props.bill) {
    return
  }

  toast({
    title: 'Payment processing',
    description: 'We are processing your payments, please wait...',
  })

  try {
    isPaying.value = true
    const { status } = await makeSingleBillPaymentWithCreditFacility({
      payment_request_data: {
        // ...(props.bill?.currency !== 'GBP' && fxQuote.value && {
        //   fx: {
        //     fx_quote_id: fxQuote.value.quote_id,
        //     amount: fxQuote.value.buy_amount,
        //     currency: props.bill.currency
        //   }
        // }),
        fx: null,
        pricing: gnplPricingDetails.value,
        payment_date: isScheduled.value
          ? scheduleDate.value
          : dayjs().toISOString(),
      },
      user: null,
      payment_type: isScheduled.value ? 'ScheduleWithGnpl' : 'PayNowWithGnpl',
      bill_id: props.bill.id,
      // agreed_to_pay_unverified_vendor: showriskConsent.value
      //   ? riskConsentAgreed.value
      //   : null,
    })

    if (status === 200) {
      showOTPModal.value = false
      // toast({
      //   title: 'Payment successful',
      //   variant: 'default',
      //   description: `Your payment was successful, please wait while we redirect you to the payments page`,
      // })

      $event('track:mixpanel', {
        event: 'GNPL payment successful',
        data: props.bill,
      })
      $event('track:mixpanel', {
        event: 'GNPL payment successful',
        data: props.bill,
      })
      // closeSummary()
      invalidateAllBillListQueries()

      if (isScheduled.value) {
        $event('track:mixpanel', {
          event: 'ScheduleBill',
          data: { ...props.bill },
        })
        goToScheduledTabAfterPayment()
      } else {
        goToPaidTabAfterPayment()
      }

      insertInvisibleElementWithIdForPendo('payment-nps-trigger')
      handleShowBulkPaymentSuggestion()
      emit('success')
    }
  } catch (error: any) {
    $event('track:mixpanel', {
      event: 'GNPL payment failed',
      data: props.bill,
    })
    toast({
      variant: 'destructive',
      title: 'Payment failed',
      description:
        error?.response?.data?.error_message ||
        error?.response?.data?.failure_reasons?.['error-message']?.[0] ||
        Object.values(error?.response?.data?.failure_reasons || {})?.[0] ||
        'Failed to make payment, please contact us at hello@lenkie.com',
    })
  } finally {
    isPaying.value = false
  }
}

const _makePayment = () => {
  if (activeTab.value === 'open-banking') {
    makeOpenBankingPayment()
  } else {
    initiatePaymentWithOTP()
  }
}

async function makeOpenBankingPayment() {
  if (!selectedBankDetails.value)
    return toast({
      title: 'No bank selected.',
      variant: 'destructive',
      description: 'Please select/add a bank',
    })

  if (!personId.value || !props.bill) return

  const payload: MakeSingleBillPaymentWithOpenBankingPayload = {
    bill_id: props.bill.id,
    payment_request_data: {
      bank_account: selectedBankDetails.value,
    },
    user: null,
    payment_type: 'OpenBank',
    // agreed_to_pay_unverified_vendor: showriskConsent.value
    //   ? riskConsentAgreed.value
    //   : null,
  }

  try {
    $event('track:mixpanel', {
      event: 'Bills payment initiated (Open Banking)',
      data: { ...props.bill },
    })
    isPaying.value = true
    await Openbanking.makeSingleBillsPaymentWithOpenBanking(payload)
  } catch (error) {
    isPaying.value = false
  } finally {
    // isPaying.value = false
  }
}

async function initiatePaymentWithOTP() {
  if (activeTab.value === 'credit-card') {
    if (!cardSelected.value) {
      return toast({
        title: 'No credit card selected.',
        variant: 'destructive',
        description: 'Please select a card',
      })
    }
  }

  if (activeTab.value === 'lenkie') {
    if (!gnplPricingDetails.value) {
      return toast({
        title: 'No Repayment periods',
        variant: 'destructive',
        description: 'Please select a repayment period',
      })
    }
  }

  if (!personId.value || !organisationId.value) return

  isPaying.value = true

  const { generateSessionCode, checkIfSessionDataExistOrValid, validate2fa } =
    CardPay

  try {
    if (!checkIfSessionDataExistOrValid()) {
      await generateSessionCode(() => (showOTPModal.value = true))
      return
    }

    const twoFaIsValid = await validate2fa()

    if (!twoFaIsValid) {
      await generateSessionCode(() => (showOTPModal.value = true))
      return
    }

    if (activeTab.value === 'credit-card') {
      await finallyMakePaymentWithCardAfterChecks()
    } else if (activeTab.value === 'lenkie') {
      await finallyMakePaymentWithCreditFacilityAfterChecks()
    }
  } catch (error) {
  } finally {
    isPaying.value = false
  }
}

const goToEditBill = () => {
  emit('editBill')
}

watchImmediate(isPaying, (newVal) => {
  emit('isBusy', newVal)
})

watchImmediate(activeTab, (_newVal) => {
  cardSelected.value = undefined
  selectedBank.value = undefined
  selectedBankDetails.value = undefined
  priceId.value = undefined
  isScheduled.value = false
  gnplPricingDetails.value = undefined
  scheduleDate.value = dayjs().add(1, 'day').toISOString()
})

onMounted(() => {
  $event('track:mixpanel', {
    event: 'Payment interface opened',
    data: { bill: { ...props.bill } },
  })
})

watchImmediate(activeTab, (newVal) => {
  $event('track:mixpanel', {
    event:
      newVal === 'lenkie'
        ? 'GNPL option selected'
        : `${newVal} option selected`,
    data: {},
  })
})
</script>

<template>
  <div class="relative z-[5000]">
    <div v-if="isValidating || isFetchingValidation" class="py-2">
      <LoadersShimmerLoader class="h-40 w-full rounded-sm" />
    </div>

    <template v-else>
      <div
        class="flex w-full items-center justify-between border-b py-2 font-normal"
      >
        <p v-if="bill?.amount" class="text-sm font-semibold text-primary/85">
          {{ getCurrency(bill.currency)
          }}{{
            new Intl.NumberFormat(undefined, {
              minimumFractionDigits: 2,
            }).format(bill.amount)
          }}
        </p>

        <div
          class="flex w-[max-content] items-center gap-x-1 rounded-full p-1 px-2 text-sm text-primary"
        >
          <span
            :class="getBillStatusClasses(bill?.bill_status)"
            class="block h-2.5 w-2.5 rounded-full"
          ></span>
          <span>
            {{ statusColors[bill?.bill_status || 'None'].value || '-' }}
          </span>
        </div>
      </div>
      <Tabs v-model="activeTab" class="mt-5">
        <BaseTabsList
          v-model="activeTab"
          :tabs="tabsShown"
          :class="isPaying ? 'pointer-events-none opacity-40' : ''"
        >
          <template #default="{ record }"
            ><span> {{ paymentMethods[record].label }}</span></template
          >
        </BaseTabsList>
        <div class="relative py-4">
          <TabsContent value="open-banking">
            <div class="relative">
              <ModulesPaymentsPaymentListOpenBanking
                v-model="selectedBank"
                v-model:bank-details="selectedBankDetails"
              />
            </div>
          </TabsContent>
          <TabsContent value="credit-card">
            <div class="relative">
              <ModulesPaymentsPaymentListCardPay
                v-model="cardSelected"
                :amount="bill.amount"
              />
            </div>
          </TabsContent>
          <TabsContent value="lenkie">
            <div class="relative">
              <ModulesPaymentsPaymentLenkiePay
                v-model:price-id="priceId"
                v-model:is-scheduled="isScheduled"
                v-model:scheduled-date="scheduleDate"
                :can-schedule="bill.currency === 'GBP'"
                :base-amount="bill.amount"
                :base-currency="bill.currency"
                @fx-quote="(val) => (fxQuote = val)"
                @pricing-details="(val) => (gnplPricingDetails = val)"
              />
            </div>
          </TabsContent>
          <div
            v-if="!paymentMethods[activeTab].isAvailable.valid"
            class="absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center gap-2 rounded bg-white/80 px-4 text-center text-primary backdrop-blur-sm"
          >
            <p>{{ paymentMethods[activeTab].isAvailable.message }}</p>
            <Button
              v-if="paymentMethods[activeTab].isAvailable.errorType === 'Bill'"
              class="px-8"
              @click="goToEditBill"
            >
              Update bill
            </Button>
          </div>

          <div v-if="paymentMethods[activeTab].isAvailable.valid" class="mt-5">
            <Button
              v-if="activeTab === 'open-banking'"
              class="w-full"
              :disabled="isPaying"
              @click="makeOpenBankingPayment"
            >
              <ph-spinner
                v-if="isPaying"
                :size="16"
                class="mr-3 animate-spin"
              />
              Proceed</Button
            >

            <Button
              v-else
              class="w-full"
              :disabled="isPaying"
              @click="initiatePaymentWithOTP()"
            >
              <ph-spinner
                v-if="isPaying"
                :size="16"
                class="mr-3 animate-spin"
              />
              Proceed</Button
            >
          </div>
        </div>
      </Tabs>
    </template>

    <ModulesPaymentsPaymentOtp
      :open="showOTPModal"
      :loading="isPaying"
      @close="showOTPModal = false"
      @update:get-pin="makeCardPayOrCreditFacilityPayment"
    />
  </div>
</template>
