<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import useVuelidate from '@vuelidate/core'
// import { helpers, required, requiredIf } from '@vuelidate/validators'
import theTextarea from '~/components/ui/textarea/the-textarea.vue'
import { vuelidateErrorMessage } from '~/lib/utils'
import { useToast } from '~/components/ui/toast'
import type { Invoice } from '~/shared/interfaces'
import type { ArchiveBillPayload } from '~/types/apiPayload/bills.payload'

interface Props {
  bills: Invoice[]
}
interface Emits {
  (e: 'success'): void
  (e: 'isBusy', v: boolean): void
}
type IForm = {
  reason: 'Already Paid' | 'Duplicate' | 'other' | ''
  reasonText: string
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const { toast } = useToast()
const { $api, $event } = useNuxtApp()

const form = reactive<IForm>({
  reason: '',
  reasonText: '',
})

const isOtherReason = computed(() => form.reason === 'other')
const rules = {
  reason: {
    // required: helpers.withMessage('Please pick a reason', required),
  },

  reasonText: {
    // requiredIf: helpers.withMessage('Tell us why!', requiredIf(isOtherReason)),
  },
}

const v$ = useVuelidate(rules, form)

const { mutate, isPending: isSubmitting } = useMutation({
  mutationFn: $api.banking.bills.archiveBill,
})

const isMultiple = computed(() => props.bills.length > 1)
const { invalidateAllBillListQueries } = useQueryUtilitiesFns()

function handleSubmit() {
  // v$.value.$touch()
  // if (v$.value.$invalid) return

  emit('isBusy', true)
  const reason = form.reason === 'other' ? form.reasonText : form.reason

  const isMultiple = props.bills.length > 1

  const payload: ArchiveBillPayload = {
    bill_id: isMultiple ? null : props.bills[0].id,
    bill_ids: isMultiple ? props.bills.map((bill) => bill.id) : null,
    reason,
  }
  mutate(payload, {
    onSuccess() {
      toast({
        title: `${isMultiple ? 'Bills' : 'Bill'} Discarded`,
        description: isMultiple
          ? `${props.bills.length} bills discarded successfully`
          : 'Bill discarded successfully',
      })

      $event('track:mixpanel', {
        event: 'Bill Archived',
        data: { ...props.bills },
      })

      invalidateAllBillListQueries()
      emit('success')
    },

    onError() {
      toast({
        title: 'Error: Bill Archive',
        description: 'Error Bill archive failed.',
        variant: 'destructive',
      })
    },

    onSettled() {
      emit('isBusy', false)
    },
  })
}
</script>

<template>
  <div>
    <form action="" class="mt-6" @submit.prevent="handleSubmit">
      <div>
        <div class="space-y-4">
          <Label class="flex items-center gap-2 text-sm text-primary">
            <input
              v-model="v$.reason.$model"
              type="radio"
              name="reason"
              value="Already Paid"
            />
            <span>
              {{
                isMultiple
                  ? 'I have already paid these bills'
                  : 'I already paid this bill'
              }}
            </span>
          </Label>
          <Label class="flex items-center gap-2 text-sm text-primary">
            <input
              v-model="v$.reason.$model"
              type="radio"
              name="reason"
              value="Duplicate"
            />
            <span>
              {{
                isMultiple
                  ? 'These are duplicate bills'
                  : 'This is a duplicate bill'
              }}
            </span>
          </Label>
          <Label class="flex items-center gap-2 text-sm text-primary">
            <input
              v-model="v$.reason.$model"
              type="radio"
              name="reason"
              value="other"
            />
            <span>Other</span>
          </Label>
        </div>
        <p v-if="v$.reason.$error" class="mt-1.5 text-sm text-red-700">
          {{ vuelidateErrorMessage(v$.reason.$errors) }}
        </p>
      </div>

      <div v-if="isOtherReason" class="mt-3 space-y-3">
        <Label class="text-primary"
          >In your own words, please tell us why</Label
        >
        <the-textarea
          v-model="v$.reasonText.$model"
          :class="{
            'border-red-700': v$.reasonText.$error,
          }"
          placeholder="Type your message here"
        />

        <p v-if="v$.reasonText.$error" class="text-sm text-red-700">
          {{ vuelidateErrorMessage(v$.reasonText.$errors) }}
        </p>
      </div>

      <Button type="submit" class="mt-6 w-full" :disabled="isSubmitting"
        >Submit{{ isSubmitting ? 'ting...' : '' }}
      </Button>
    </form>
  </div>
</template>
